import CV from './screens/Cv';
import Home from './screens/Home';
import PdfViewer from './screens/PdfViewer';
import './static/css/Index.css';
import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom"

function App() {


  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {/* <Route path={Endpoints.artisteadvice + "/:postId?/:postTitle?"} element={<BlogView />} /> */}
        <Route path='/' element={<Home/>} />
        <Route path='/jobs/cv' element={<CV/>} />
        <Route path="/books/javascript/fundamental-to-pro-the-javascript-path-to-web-mastery/edition-1" element={<PdfViewer/>} />
      </>
    )
  );

  return (<RouterProvider router={router} />);
}

export default App;
