import React from 'react';

const About = () => {
    return (
        <div>
            <section id="about" className="section">
                <h2>About Me</h2>
                <p>
                    I am a highly skilled and innovative engineer with a strong background in Computer Science and Electrical Electronics Engineering.
                    My dedication to technology and my entrepreneurial spirit have led me to co-found Hitek Financials Limited, where I have successfully developed fintech applications, integrated with payment gateways, and worked with cryptocurrency and NFTs.
                    I am passionate about software architectural design, microservices, and system automation, and I excel in full-stack development.
                </p>
            </section>
        </div>
    );
};

export default About;