import React, { useRef } from "react";
import { useInView } from "framer-motion";

const CustomAnimation = ({ children, duration = 0.1, delay = 1.5}) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <section ref={ref}>
      <span
        style={{
          transform: isInView ? "none" : "translateY(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all "+delay+"s cubic-bezier(0.30, 0.80, 0.99, 1) "+duration+"s ",
        }}
      >
        {children}
      </span>
    </section>
  );
};
export default CustomAnimation;
