import React from 'react';

const Education = () => {
    return (
        <div>
            {/* Education */}
            <section className="section">
                <h2>Education</h2>
                {/* <p>
                    <strong>Ph.D. in Computer Science (In view)</strong><br />
                    Zhejiang University of Technology, Hangzhou, China<br />
                    Research Focus: Nanonetworks Communication Protocol and Wireless Sensor Networks (WSN)
                </p> */}
                <p>
                    <strong>Master of Computer Science</strong><br />
                    Zhejiang University of Technology, Hangzhou, China<br />
                    Focus: IoT/Terahertz Telecommunication
                </p>
                <p>
                    <strong>Bachelor of Computer Science and Engineering Technology</strong><br />
                    South China University of Technology, Guangzhou, China
                </p>
                <p>
                    <strong>National Diploma (ND) in Electrical Electronics Engineering</strong><br />
                    Edo State Institute of Technology and Management Studies, Usen, Edo State, Nigeria
                </p>
            </section>

        </div>
    );
};

export default Education;