import React from 'react';
import About from './components/About';
import Projects from './components/Projects';
import SideBarNav from './components/SideBarNav';
import HomeWelcome from './components/HomeWelcome';
import HomeBookView from './components/HomeBookView';
import HomeCvView from './components/HomeCvView';
import ContactUs from './components/ContactUs';
import CustomAnimation from './components/CustomAnimation';
import FooterSection from './components/FooterSection';

function Home() {
    return (
        <div className="App">
            <SideBarNav />
            <div className="content">
                {/* Home Section */}
                <CustomAnimation>
                    <HomeWelcome />
                </CustomAnimation>
                {/* About Section */}
                <CustomAnimation>
                    <About />
                </CustomAnimation>
                {/* Projects Section */}
                <CustomAnimation>
                    <Projects />
                </CustomAnimation>
                {/* Books Section */}
                <CustomAnimation>
                    <HomeBookView />
                </CustomAnimation>
                {/* CV Section */}
                <CustomAnimation>
                    <HomeCvView />
                </CustomAnimation>
                {/* Contact Section */}
                <CustomAnimation>
                    <ContactUs />
                </CustomAnimation>
                <CustomAnimation>
                    <FooterSection />
                </CustomAnimation>
            </div>
        </div>
    );
}

export default Home;
