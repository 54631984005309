import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import CustomAnimation from './components/CustomAnimation';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfViewer = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  return (

    <div className="pdf-viewer">
            <Link style={{height:'20px',padding: '10px 20px',position:'fixed', zIndex:'1000',left:'20px', color: '#ffffff', textDecoration:'none', textAlign:'left', width:'100%'}} to="/"><FaArrowLeft/> Back</Link>
      <div className="controls">
        <p>
          Page {pageNumber} of {numPages}
        </p>
        <button
          onClick={() => handlePageChange(pageNumber - 1)}
          disabled={pageNumber <= 1}
        >
          Previous
        </button>
        <button
          onClick={() => handlePageChange(pageNumber + 1)}
          disabled={pageNumber >= numPages}
        >
          Next
        </button>
      </div>
      <div className="pdf-container">
        <Document
          className="pdf-file-container"
          file={require('../static/book/basics.pdf')}
          onLoadSuccess={onDocumentLoadSuccess}
          loading="Loading.. | Fundamental to Pro: The JavaScript Path to Web Mastery"
        >
          <CustomAnimation>
              <Page pageNumber={pageNumber} width={700} />
          </CustomAnimation>
        </Document>
      </div>
      <br/>
      <div style={{marginTop: '20px'}} className="controls">
        <p>
          Page {pageNumber} of {numPages}
        </p>
        <button
          onClick={() => handlePageChange(pageNumber - 1)}
          disabled={pageNumber <= 1}
        >
          Previous
        </button>
        <button
          onClick={() => handlePageChange(pageNumber + 1)}
          disabled={pageNumber >= numPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PdfViewer;