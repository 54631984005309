import React from 'react';
const Skills = () => {
    return (
        <>
            <section className="section">
                <h2>Skills</h2>
                <p>
                    <strong>Software Architectural Design</strong><br />
                    I possess strong expertise in software architectural design. In my role as a Senior Backend Engineer and DevOps specialist at Zabira Technologies, I led the architectural design of complex systems that required scalability, high availability, and efficient resource management. This involved designing microservices, optimizing database structures, and selecting appropriate technologies for each component. My architectural decisions contributed to reduced downtime, improved system performance, and enhanced user experiences.
                </p>
                <p>
                    <strong>System Automation (CI/CD Pipeline)</strong><br />
                    As a DevOps engineer, I have extensive experience in system automation using CI/CD pipelines. At PaybuyMax, I implemented automated deployment pipelines that allowed for continuous integration and delivery of software updates. This resulted in faster release cycles, reduced manual errors, and improved collaboration among development and operations teams. I am well-versed in tools such as Jenkins, Travis CI, and Docker for building and deploying applications.
                </p>
                <p>
                    <strong>Linux Web Server Administration</strong><br />
                    I have a proven track record of Linux web server administration and setup. While working at Bizsco Company, I managed and maintained Linux-based web servers hosting critical applications. I configured server security, optimized server performance, and ensured high availability through load balancing and redundancy strategies. My expertise in Linux administration contributed to the reliability and stability of the hosting infrastructure.
                </p>
                <p>
                    <strong>Full Stack Development</strong><br />
                    My experience as a Full Stack Developer spans various roles and projects. At Topclassreporters LLC, I led development teams in creating end-to-end solutions, from designing user interfaces and writing front-end code to developing server-side logic and managing databases. My proficiency in multiple programming languages, including Python, PHP, Java, and JavaScript (ReactJs Framework), allows me to deliver comprehensive and cohesive applications that meet business objectives.
                </p>
                <p>
                    <strong>Programming Languagess</strong><br />
                    Proficiency in
                    <ol>
                        <li>Python and Framework (Flask)</li>
                        <li>PHP and Laravel Framework</li>
                        <li>JavaScript and Frameworks (ReactJs, JQuery, Eletron), writing PWA</li>
                        <li>HTML</li>
                        <li>CSS,SASS,LESS and Frameworks (Tailwind,Bootstrap) </li>
                        <li>Golang</li>
                        <li>Java</li>
                        <li>Nodejs</li>
                        <li>Flutter & Dart</li>
                        <li>React Native</li>
                        <li>Shell Script for Automation</li>
                        <li>yaml scripting</li>
                    </ol>
                </p>
                <p>
                    <strong>DevOps</strong>
                    <ol>
                        <li>Linux webserver configuration & administration </li>
                        <li>Docker for Containerized application</li>
                        <li>RabbitMQ for messaging broker and queueing</li>
                        <li>Celery for task processing and auto scalling</li>
                        <li>Kubernetes</li>
                    </ol>
                </p>
                <p>
                    <strong>Financial API Development and Security</strong><br />
                    As a co-founder of Hitek Financials Limited, I played a pivotal role in developing financial APIs that adhere to design patterns and ISO security standards. These APIs enabled secure and efficient financial transactions for our clients. I am well-versed in implementing encryption, authentication, and authorization mechanisms to safeguard sensitive financial data and ensure compliance with industry regulations.
                </p>
                <p>
                    <strong>Project Management</strong><br />
                    I have extensive experience in project management using Jira and Git. While working at PaybuyMax, I used Jira to plan, track, and prioritize development tasks, ensuring that projects stayed on schedule and within budget. My proficiency in Git allowed for effective version control and collaborative software development. I facilitated communication among cross-functional teams, conducted code reviews, and documented project progress using Confluence and Postman.
                </p>
                <p>
                    <strong>Training</strong><br />
                    I have a passion for knowledge sharing and have conducted training sessions on various topics, including Linux system administration, FrontEnd development, Python programming, and Data Structures and Algorithms using Python and Java. These training sessions equipped students with valuable skills and enhanced their understanding of key concepts. I believe in empowering others through education and mentorship.
                </p>
            </section>
        </>
    );
};

export default Skills;