import React from 'react';

const ContactUs = () => {
    return (
        <div>
            <section id="contact" className="section">
                <h2>Contact Me</h2>
                <p>
                    You can reach me at the following contact details:
                    <ul>
                        <li>Address: Shima Shetu, Xihu District, Hangzhou, China</li>
                        <li>Email: <a href="mailto:kennethusiobaifo@yahoo.com">kennethusiobaifo@yahoo.com</a>, <a href="mailto:usiobaifokenneth@gmail.com">usiobaifokenneth@gmail.com</a></li>
                        <li>Website: <a href="https://usiobaifokenneth.com">https://usiobaifokenneth.com</a></li>
                        <li>Tel: +8613073040862, +2348140144858</li>
                    </ul>
                </p>
            </section>
        </div>
    );
};

export default ContactUs;