import React from 'react';
import flaretech from "../../static/images/flaretech.webp";
import zabira from "../../static/images/zabira.webp";
import zabira2 from "../../static/images/grip.webp";
import flaretech2 from "../../static/images/flaretech2.webp";
import zabira1 from "../../static/images/grip2.webp";
import tcr from "../../static/images/tcr.webp";

const Projects = () => {
    return (
        <div>
            <section id="projects" className="section">
                <h2>Projects</h2>
                <div className="project-item">
                    <div className="project-content">
                        <h3>Zabira Technologies</h3>
                        <p>Transformed the crypto website API from monolith to microservices API.</p>
                        <br /><br />
                        <strong>About Zabira Technologies</strong>
                        <p>Zabira Technologies is a crypto platform for selling and buying crypto currencies, and grip for selling of all kinds of giftcards
                        <br/>
                        <a href="https://zabira.ng" target="_blank" rel="noopener noreferrer">Visit Zabira</a>
                        </p>
                    </div>
                    <div className="project-image">
                        <div className='images-wrap'>
                            <img src={zabira} alt="Zabira Technologies" />
                            <img src={zabira1} alt="Zabira Technologies" />
                            <img src={zabira2} alt="Zabira Technologies" />
                        </div>
                    </div>
                </div>
                <div className="project-item">
                    <div className="project-content">
                        <h3>Telvida Technologies</h3>
                        <p>Built the One reach app for video conferencing using WebRTC.</p>
                    </div>
                    <div className="project-image">
                        {/* <img src={telvida} alt="Telvida Technologies" /> */}
                    </div>
                </div>
                <div className="project-item">
                    <div className="project-content">
                        <h3>PaybuyMax</h3>
                        <p>Built a crypto platform using React and PHP as the backend language.</p>
                    </div>
                    <div className="project-image">
                        {/* <img src={pbm} alt="PaybuyMax" /> */}
                    </div>
                </div>
                <div className="project-item">
                    <div className="project-content">
                        <h3>Bizsco Company</h3>
                        <p>Built a chat-commerce and payment app using Flutter & Dart.</p>
                    </div>
                    <div className="project-image">
                        {/* <img src={bizsco} alt="Bizsco Company" /> */}
                    </div>
                </div>
                <div className="project-item">
                    <div className="project-content">
                        <h3>Flaretechmusic Nigeria Limited</h3>
                        <p>Flaretechmusic is a music distribution platform that make distributing music in all social media platform easy with just a click of a button from your device</p>
                        <br /><br />
                        <strong>About Flaretechmusic</strong>
                        <p>Flaretechmusic make music distribution very simple and easy, enabling you to maximize revenue, visibility, and traction. Release your music with FlareTechMusic today; Get discovered, grow your fan base, and keep 100% of your copyrights and earnings.
                        <br/>
                        <a href="https://flaretechmusic.com" target="_blank" rel="noopener noreferrer">Visit flaretechmusic</a>
                        </p>
                    </div>
                    <div className="project-image">
                        <div className='images-wrap'>
                            <img src={flaretech2} alt="Flaretechmusic" />
                            <img src={flaretech} alt="Flaretechmusic" />
                        </div>
                    </div>
                </div>
                <div className="project-item">
                    <div className="project-content">
                        <h3>Topclassreporters LLC</h3>
                        <p>Developed systems using HTML, CSS, JavaScript, and PHP.</p>
                        <br /><br />
                        <strong>About Topclassreporters LLC</strong>
                        <p>Topclassreporters is a News media website where you can read News, share comments and likes.
                        <br/>
                        <a href="https://topclassreporters.com" target="_blank" rel="noopener noreferrer">Visit Topclassreporters</a>
                        </p>
                    </div>
                    <div className="project-image">
                        <img src={tcr} alt="Topclassreporters LLC" />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Projects;