import React, { useState } from 'react';
import myImage from "../../static/images/kenneth.webp";

const SideBarNav = () => {
    const [activeSection, setActiveSection] = useState('home');

    const handleMenuItemClick = (section) => {
        setActiveSection(section);
        document.getElementById(section).scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
        <div className='my-profile-image'>
                <img src={myImage} alt="Usiobaifo Kenneth" />
                <h1>Usiobaifo A Kenneth</h1>
        </div>
        <div className="sidebar">
            <button
                className={`menu-item ${activeSection === 'home' ? 'active' : ''}`}
                onClick={() => handleMenuItemClick('home')}
            >
                Home
            </button>
            <button
                className={`menu-item ${activeSection === 'about' ? 'active' : ''}`}
                onClick={() => handleMenuItemClick('about')}
            >
                About
            </button>
            <button
                className={`menu-item ${activeSection === 'projects' ? 'active' : ''}`}
                onClick={() => handleMenuItemClick('projects')}
            >
                Projects
            </button>
            <button
                className={`menu-item ${activeSection === 'books' ? 'active' : ''}`}
                onClick={() => handleMenuItemClick('books')}
            >
                Books
            </button>
            <button
                className={`menu-item ${activeSection === 'cv' ? 'active' : ''}`}
                onClick={() => handleMenuItemClick('cv')}
            >
                CV
            </button>
            <button
                className={`menu-item ${activeSection === 'contact' ? 'active' : ''}`}
                onClick={() => handleMenuItemClick('contact')}
            >
                Contact
            </button>
        </div>
        </>
    );
};

export default SideBarNav;