import React from 'react';
import Experience from './components/Experience';
import Skills from './components/Skills';
import Education from './components/Education';
import Introduction from './components/Introduction';
import HeaderSection from './components/HeaderSection';
import FooterSection from './components/FooterSection';
import CustomAnimation from './components/CustomAnimation';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const CV = () => {
  return (
    <div className="cv">
            <Link style={{height:'20px',padding: '10px 20px',position:'fixed', zIndex:'1000',left:'20px', color: '#ffffff', textDecoration:'none', textAlign:'left', width:'100%'}} to="/"><FaArrowLeft/> Back</Link>
      {/* Header */}
      <CustomAnimation>
        <HeaderSection/>
      </CustomAnimation>
      {/* Introduction */}
      <CustomAnimation>
        <Introduction/>
      </CustomAnimation>
      {/* education */}
      <CustomAnimation>
        <Education/>
      </CustomAnimation>
      {/* Professional Experience */}
      <CustomAnimation>
        <Experience/>
      </CustomAnimation>
      {/* skills */}
      <CustomAnimation>
        <Skills/>
      </CustomAnimation>
      {/* References */}
      {/* <CustomAnimation>
        <References/>
      </CustomAnimation> */}
      <hr />
      <CustomAnimation>
        <FooterSection/>
      </CustomAnimation>
    </div>
  );
};

export default CV;
