import React from 'react';
import myImage from "./../../static/images/kenneth.webp";

const HeaderSection = () => {
    return (
        <div>
            <header className="header">
                <div className="profile-image">
                    <img src={myImage} alt="" srcset="" />
                </div>
                <div className="header-content">
                    <h1>Engr Usiobaifo .A. Kenneth</h1>
                    <p>Marital Status: Married</p>
                    <p>Current Address: Shima Shetu, Xihu, Hangzhou, China</p>
                    <p>Tel: +8613073040862, +2348140144858</p>
                    <p>Email: <a href="mailto:kennethusiobaifo@yahoo.com">kennethusiobaifo@yahoo.com</a>, <a href="mailto:usiobaifokenneth@gmail.com">usiobaifokenneth@gmail.com</a></p>
                    <p>Website: <a href="https://usiobaifokenneth.com/jobs/cv">https://usiobaifokenneth.com/jobs/cv</a></p>
                    <div className='author-wrap'>
                        <p><strong>Author:</strong> Fundamental to Pro: The JavaScript Path to Web Mastery <br /><br /><a href="/books/javascript/fundamental-to-pro-the-javascript-path-to-web-mastery/edition-1">Read the Fundamental ( Chapter 1-5 )</a></p>
                    </div>
                </div>
            </header>
        </div>
    );
};

export default HeaderSection;