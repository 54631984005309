import React from 'react';

const Experience = () => {
  return (
    <div className="section professional-expirience">
      <section>
        <h2>Professional Experience</h2>
        <p>
          <strong>Co-Founder | Hitek Financials Limited</strong>
          <ul>
            <li>Co-founded and managed a technology-focused company specializing in financial solutions.</li>
            <li>Developed fintech applications for seamless transactions, cryptocurrency, and NFTs.</li>
            <li>Integrated with payment gateways and developed API and email servers.</li>
            <li>Worked with Web3 APIs for blockchain applications.</li>
          </ul>
        </p>
    </section>
      <section>
      <strong>Senior Backend Engineer, DevOps | Zabira Technologies</strong>
        <p>2023</p>
        <ul>
          <li>Accomplishments and responsibilities in this role:
            <ul>
              <li>Transformed the crypto website API from a monolith to a microservices-based architecture.</li>
              <li>Implemented scalable and efficient microservices for improved performance and maintainability.</li>
              <li>Collaborated with cross-functional teams to ensure seamless integration of microservices.</li>
            </ul>
          </li>
          <li>Projects and technologies:
            <ul>
              <li>Crypto Website API Transformation: Led the transformation of the crypto website API from monolith to microservices.</li>
              <li>Microservices Development: Developed various microservices using technologies such as Node.js, Docker, and Kubernetes.</li>
            </ul>
          </li>
        </ul>
      </section>

      <section>
      <strong>Mobile Application Developer with React Native | Telvida Technologies</strong>
        <p>2022</p>
        <ul>
          <li>Accomplishments and responsibilities in this role:
            <ul>
              <li>Designed and developed the "One Reach" app, enabling video conferencing using WebRTC technology.</li>
              <li>Implemented real-time video streaming and conferencing features for seamless user interactions.</li>
              <li>Collaborated with the development team to ensure high-quality app delivery.</li>
            </ul>
          </li>
          <li>Projects and technologies:
            <ul>
              <li>One Reach App: Designed and built the "One Reach" app for video conferencing using React Native and WebRTC.</li>
            </ul>
          </li>
        </ul>
      </section>

      <section>
        <strong>Team Lead/Senior Full-Stack Engineer | PaybuyMax</strong>
        <p>2021</p>
        <ul>
          <li>Accomplishments and responsibilities in this role:
            <ul>
              <li>Led the development of the PaybuyMax crypto platform, serving as the team lead.</li>
              <li>Utilized React for the front-end and PHP as the backend language to create a secure and efficient platform.</li>
              <li>Collaborated with the team to ensure the successful launch of the platform.</li>
            </ul>
          </li>
          <li>Projects and technologies:
            <ul>
              <li>PaybuyMax Crypto Platform: Led the development of the crypto platform using React (front-end) and PHP (back-end).</li>
            </ul>
          </li>
        </ul>
      </section>

      <section>
        <strong>Mobile Engineer/Developer with Flutter & Dart | Bizsco Company</strong>
        <p>2019</p>
        <ul>
          <li>Accomplishments and responsibilities in this role:
            <ul>
              <li>Developed the Chat-Commerce and Payment app using Flutter and Dart, providing users with a seamless chat and payment experience.</li>
              <li>Designed and implemented secure payment functionalities.</li>
            </ul>
          </li>
          <li>Projects and technologies:
            <ul>
              <li>Chat-Commerce and Payment App: Built the app using Flutter and Dart, focusing on chat and secure payment features.</li>
            </ul>
          </li>
        </ul>
      </section>

      <section>
        <strong>Fullstack/Team Lead | Topclassreporters LLC</strong>
        <p>2015</p>
        <ul>
          <li>Accomplishments and responsibilities in this role:
            <ul>
              <li>Led the development of systems using HTML, CSS, JavaScript, and PHP.</li>
              <li>Collaborated with a team to create comprehensive web solutions.</li>
              <li>Ensured the successful delivery of projects on time.</li>
            </ul>
          </li>
          <li>Projects and technologies:
            <ul>
              <li>Web Development: Led the development of various web systems using HTML, CSS, JavaScript, and PHP.</li>
            </ul>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Experience;
