import React from 'react';
import { Link } from 'react-router-dom';

const HomeCvView = () => {
    return (
        <div>
            <section id="cv" className="section">
                <h2>CV</h2>
                <p>You can view my CV here.</p>
                {/* Add a link to your CV */}
                <Link to="/jobs/cv" >View Cv</Link>
                {/* <a href="https://cv.usiobaifokenneth.com" target="_blank" rel="noopener noreferrer">View CV</a> */}
            </section>
        </div>
    );
};

export default HomeCvView;