import React from 'react';

const FooterSection = () => {
    return (
        <div>
            <div style={{ textAlign: "center", color:'#e1e1e1', paddingBottom: '20px'}}>
                <p>Allrights reserved | Engineer Usiobaifo Kenneth</p>
            </div>
        </div>
    );
};

export default FooterSection;