import React from 'react';
import { Link } from 'react-router-dom';

const HomeBookView = () => {
    return (
        <div>
            <section id="books" className="section">
                <h2>Books</h2>
                <p>
                    I am the author of these books
                </p>
                <Link to="/books/javascript/fundamental-to-pro-the-javascript-path-to-web-mastery/edition-1">Fundamental to Pro: The JavaScript Path to Web Mastery.</Link>
                {/* <a href="https://cv.usiobaifokenneth.com/books/javascript/fundamental-to-pro-the-javascript-path-to-web-mastery/edition-1" target="_blank" rel="noopener noreferrer">Coding Python like a pro</a>
          <a href="https://cv.usiobaifokenneth.com/books/javascript/fundamental-to-pro-the-javascript-path-to-web-mastery/edition-1" target="_blank" rel="noopener noreferrer">Python for smart object: Python of things PoT</a> */}
            </section>
        </div>
    );
};

export default HomeBookView;