import React from 'react';

const HomeWelcome = () => {
    return (
        <div>
            <section id="home" className="section">
                <h2>Welcome to My Portfolio</h2>
                <p>
                    My Name is Engr Usiobaifo A Kenneth, and I am a tech expert with a diverse skill set in programming, software engineering, and Linux web server administration.
                    I am also an author, mobile app developer, researcher, and trainer.
                    With a passion for technology, I have dedicated my career to pushing the boundaries of what's possible in the world of software development and IT.
                    Explore my portfolio to learn more about my work and achievements.
                </p>
            </section>
        </div>
    );
};

export default HomeWelcome;